import { keyframes, styled } from '@mui/material/styles';
import React from 'react';
import Markdown from 'react-markdown';

export const Messages = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  overflowY: 'auto',
  flexGrow: 1,
  order: 2,
});

const MessageBase = styled('div')({
  margin: '8px 0',
  padding: '8px 16px',
  alignSelf: 'flex-start',
  borderRadius: '15px',
  maxWidth: '80%',
  overflowWrap: 'break-word',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
    padding: '6px 12px',
    maxWidth: '90%',
  },
});

export const UserMessageStyled = styled(MessageBase)({
  alignSelf: 'flex-end',
  color: '#fff',
  backgroundColor: '#000',
});

export const AssistantMessageStyled = styled(MessageBase)({
  backgroundColor: '#efefef',
  '& img': {
    maxWidth: '100%',
    margin: '8px 0px',
    borderRadius: '8px',
  },
});

export const CodeMessageStyled = styled(MessageBase)({
  padding: '10px 16px',
  backgroundColor: '#e9e9e9',
  fontFamily: 'monospace',
  counterReset: 'line',
  '& > div': {
    marginTop: '4px',
  },
  '& span': {
    color: '#b8b8b8',
    marginRight: '8px',
  },
});

const dotAnimation = keyframes`
  0%, 20% { content: ''; }
  30% { content: '.'; }
  50% { content: '..'; }
  70% { content: '...'; }
  100% { content: ''; }
`;

const ThinkingMessage = styled(MessageBase)({
  backgroundColor: '#efefef',
  '&::after': {
    content: "'.'",
    animation: `${dotAnimation} 1.5s infinite`,
  },
});

export type MessageProps = {
  role: 'user' | 'assistant' | 'code';
  text: string;
};

const UserMessage = ({ text }: { text: string }) => {
  return <UserMessageStyled>{text}</UserMessageStyled>;
};

const AssistantMessage = ({ text }: { text: string }) => {
  return (
    <AssistantMessageStyled>
      <Markdown>{text}</Markdown>
    </AssistantMessageStyled>
  );
};

const CodeMessage = ({ text }: { text: string }) => {
  return (
    <CodeMessageStyled>
      {text.split('\n').map((line, index) => (
        <div key={index}>
          <span>{`${index + 1}. `}</span>
          {line}
        </div>
      ))}
    </CodeMessageStyled>
  );
};

const ThinkingIndicator = () => {
  return <ThinkingMessage>Thinking</ThinkingMessage>;
};

export const Message = ({ role, text }: MessageProps): React.ReactElement => {
  switch (role) {
    case 'user':
      return <UserMessage text={text} />;
    case 'assistant':
      return text === 'Thinking...' ? <ThinkingIndicator /> : <AssistantMessage text={text} />;
    case 'code':
      return <CodeMessage text={text} />;
    default:
      return <div>{text}</div>;
  }
};
