import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalFloristTwoToneIcon from '@mui/icons-material/LocalFloristTwoTone';
import { Card, Collapse, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { GoogleMap, StandaloneSearchBox } from '@react-google-maps/api';
import { ErrorBoundary } from '@sentry/react';
import type { Coordinates } from '@soilsense/shared';
import { isPointInPolygon } from 'geolib';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { JsonDecoder } from 'ts.data.json';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import { useFarmStore } from '../../dataHandlers/RootStore';
import { customerVisibleDeviceId } from '../../dataHandlers/utils/formatters';
import type { AreaId } from '../../interfaces/Area';
import type { FieldWithId } from '../../interfaces/Field';
import {
  BRIGHT_NEUTRAL_COLOR,
  DARK_NEUTRAL_COLOR,
  getIrrigationStatus,
  getMarkerColorForIrrigationStatus,
  INTERACTION_COLOR,
  NEUTRAL_COLOR,
} from '../../utils/getMarkerColorFromReadings';
import type { AddFieldOverlayHandle } from './AddFieldOverlay';
import AddFieldOverlay from './AddFieldOverlay';
import { FieldShape } from './FieldShape';
import { MouseEventFriendlyMarker } from './MouseEventFriendlyMarker';

type NdviDate = Readonly<{
  date: string;
}>;

const useStyles = makeStyles(() => ({
  innerContainer: {
    borderRadius: (props: { borderRadius: number }) => props.borderRadius,
    height: '100%',
    width: '100%',
  },
  largeButton: {
    padding: 20,
  },
  largeIcon: {
    fontSize: '2.5em',
  },
}));

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

interface IProps {
  siteDetails: readonly SiteDetails[];
  selectedArea?: AreaId;
  setSelectedArea?: React.Dispatch<AreaId | undefined>;
  handleSiteClick?: React.Dispatch<string>;
  handleFieldClick?: React.Dispatch<string>;
  borderRadius: number;
  zoomControl?: boolean;
  searchBoxVisible?: boolean;
  center: Coordinates | undefined;
  zoom: number;
  onZoomChange?: (zoom: number) => void;
  showNdviLayer?: boolean;
}

const NDVI_OPACITY = 0.7;

const DEFAULT_CENTER: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
const DEFAULT_ZOOM = 2;

const MarkersMap: FC<IProps> = observer(
  ({
    siteDetails,
    selectedArea,
    setSelectedArea,
    handleSiteClick,
    handleFieldClick,
    borderRadius,
    zoomControl = false,
    searchBoxVisible = false,
    center,
    zoom,
    onZoomChange,
    showNdviLayer = false,
  }) => {
    const farmStore = useFarmStore();

    if (!farmStore.selectedFarmId) {
      return null;
    }

    const classes = useStyles({ borderRadius });

    const [mapInstance, setMapInstance] = useState<google.maps.Map>();
    const mapOnLoad = useCallback((map: google.maps.Map) => {
      setMapInstance(map);
    }, []);
    const intl = useIntl();

    useEffect(() => {
      if (mapInstance != undefined && center != undefined && !isNaN(center.lat) && !isNaN(center.lng)) {
        if (mapInstance.getCenter() == undefined) {
          // forcefully initialize map center because panTo() does not have any
          // effect while the map instance is not initialized
          center && mapInstance.setCenter(center);
        } else {
          center && mapInstance.panTo(center);
        }
      } else {
        if (mapInstance != undefined && mapInstance.getCenter() == undefined) {
          mapInstance.panTo(DEFAULT_CENTER);
          mapInstance.setZoom(DEFAULT_ZOOM);
        }
      }
    }, [center, mapInstance]);

    const handleZoomChanged = useCallback(() => {
      const zoom = mapInstance?.getZoom();
      if (zoom !== undefined) {
        onZoomChange?.(zoom);
      }
    }, [mapInstance, onZoomChange]);

    const [ndviShown, setNdviShown] = useState(false);
    const [ndviLayer, setNdviLayer] = useState<google.maps.ImageMapType>();
    const [availableNdviDates, setAvailableNdviDates] = useState<readonly NdviDate[]>([]);
    const [selectedNdviDate, setSelectedNdviDate] = useState(0);
    const [searchOpen, setSearchOpen] = useState(false);

    useEffect(() => {
      let active = true;
      (async () => {
        if (showNdviLayer) {
          const dates = await fetchNdviDates();
          if (active) {
            setAvailableNdviDates(dates);
          }
        } else {
          setAvailableNdviDates([]);
        }
      })();
      return () => {
        active = false;
      };
    }, [showNdviLayer]);

    useEffect(() => {
      let active = true;
      if (availableNdviDates && availableNdviDates.length && selectedNdviDate != null) {
        (async () => {
          const { date } = availableNdviDates[selectedNdviDate];
          const mapid = await fetchNdviLayerId(date);
          if (active && mapid != undefined) {
            const eeMapOptions = {
              opacity: NDVI_OPACITY,
              getTileUrl: (tile: google.maps.Point, tileZoom: number) => {
                const url = [
                  'https://earthengine.googleapis.com/v1alpha',
                  mapid,
                  'tiles',
                  tileZoom,
                  tile.x,
                  tile.y,
                ].join('/');
                return url;
              },
              tileSize: google ? new google.maps.Size(256, 256) : undefined,
            };

            setNdviLayer(google ? new google.maps.ImageMapType(eeMapOptions) : undefined);
          }
        })();
      }
      return () => {
        active = false;
      };
    }, [availableNdviDates, selectedNdviDate]);

    useEffect(() => {
      if (ndviShown) {
        mapInstance?.overlayMapTypes.clear();
        if (ndviLayer != null) {
          mapInstance?.overlayMapTypes.push(ndviLayer);
        }
      }
    }, [ndviLayer, mapInstance?.overlayMapTypes, ndviShown]);

    const toggleNdvi = () => {
      const overlayMapTypes = mapInstance?.overlayMapTypes;
      if (overlayMapTypes?.getLength() === 0) {
        if (ndviLayer != null) {
          overlayMapTypes.push(ndviLayer);
        }
      } else {
        overlayMapTypes?.clear();
      }
      setNdviShown((v) => !v);
    };

    const NdviNavigation = () => {
      return (
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            right: 100,
            backgroundColor: 'white',
            color: 'black',
            height: 100,
            width: 100,
            bottom: 5,
          }}
        >
          <div style={{ textAlign: 'center', fontWeight: 900, height: 23, paddingTop: 5 }}>NDVI</div>
          <div style={{ textAlign: 'center' }}>
            <IconButton
              onClick={() => {
                setSelectedNdviDate((p) => {
                  if (p == null) {
                    return 0;
                  }
                  return Math.min((p as number) + 1, availableNdviDates.length);
                });
              }}
              size='large'
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedNdviDate((p) => {
                  if (p == null) {
                    return 0;
                  }
                  return Math.max((p as number) - 1, 0);
                });
              }}
              size='large'
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
          {selectedNdviDate != null && availableNdviDates.length && (
            <div style={{ textAlign: 'center' }}>{availableNdviDates[selectedNdviDate].date}</div>
          )}
        </Card>
      );
    };

    const [fieldDraft, setFieldDraft] = useState<FieldWithId>();
    // make a copy of the field draft coordinates just to make geolib types happy
    const fieldDraftPath = useMemo(() => (fieldDraft == undefined ? [] : [...fieldDraft.path]), [fieldDraft]);
    const addFieldOverlayRef = useRef<AddFieldOverlayHandle | null>(null);

    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

    const onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
      setSearchBox(ref);
    };

    const onPlacesChanged = () => {
      if (searchBox) {
        const places = searchBox.getPlaces();
        // setSearchResults(places || []);
        setSearchOpen(false);
        if (places && places.length > 0 && places[0].geometry && places[0].geometry.location) {
          mapInstance?.panTo(places[0].geometry.location);
          mapInstance?.setZoom(15);
        }
      }
    };

    const handleSearch = (value: string) => {
      const coords = parseCoordinates(value);
      if (coords) {
        mapInstance?.panTo(coords);
        mapInstance?.setZoom(15);
      } else {
        // Existing place search logic
        if (searchBox) {
          const places = searchBox.getPlaces();
          // setSearchResults(places || []);
          if (places && places.length > 0 && places[0].geometry && places[0].geometry.location) {
            mapInstance?.panTo(places[0].geometry.location);
            mapInstance?.setZoom(15);
          }
        }
      }
    };

    return (
      <ErrorBoundary fallback={<div>Error loading map</div>}>
        {searchBoxVisible && (
          <div style={{ position: 'absolute', top: 15, left: 15, zIndex: 1000 }}>
            {!searchOpen ? (
              <IconButton
                className='search-icon-button'
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: 10,
                }}
                onClick={() => {
                  setSearchOpen(true);
                }}
              >
                <SearchIcon className='search-icon' />
              </IconButton>
            ) : (
              <>
                <Collapse in={searchOpen} orientation='horizontal' timeout={500}>
                  <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
                    <TextField
                      variant='outlined'
                      placeholder={intl.formatMessage({ id: 'search_for_a_location_or_enter_coordinates' })}
                      style={{
                        width: searchOpen ? '90vw' : 0,
                        maxWidth: 400,
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        transition: 'width 0.5s ease-in-out',
                      }}
                      InputProps={{
                        type: 'text',
                        style: {
                          borderRadius: '20px',
                        },
                        endAdornment: (
                          <IconButton onClick={() => setSearchOpen(false)} edge='end'>
                            <CloseIcon />
                          </IconButton>
                        ),
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch((e.target as HTMLInputElement).value);
                          setSearchOpen(false);
                        }
                      }}
                      autoFocus
                    />
                  </StandaloneSearchBox>
                </Collapse>
                <style>
                  {`
                  .pac-container {
                    margin-left: 15px !important;
                    border-radius: 10px !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    margin-top: -1px !important;
                    cursor: pointer !important;
                    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
                    // left: 15px !important;
                    // width: calc(90vw - 30px) !important;
                    // max-width: 370px !important;
                  }
                `}
                </style>
              </>
            )}
          </div>
        )}
        <GoogleMap
          mapContainerClassName={classes.innerContainer}
          zoom={zoom}
          onZoomChanged={handleZoomChanged}
          options={{
            zoomControl,
            styles: mapStyles,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            mapTypeId: google ? google.maps.MapTypeId.HYBRID : 'hybrid',
          }}
          onLoad={mapOnLoad}
          onClick={(event) => {
            const { latLng } = event;
            if (latLng != undefined) {
              const action = addFieldOverlayRef.current?.handleMapClick({
                lat: latLng.lat(),
                lng: latLng.lng(),
              });
              if (action == 'stop') {
                event.stop();
              } else {
                setSelectedArea?.(undefined);
              }
            }
          }}
        >
          {setSelectedArea != undefined && (
            <AddFieldOverlay
              ref={addFieldOverlayRef}
              fieldDraft={fieldDraft}
              setFieldDraft={setFieldDraft}
              setSelectedArea={setSelectedArea}
            />
          )}
          {farmStore.selectedFarmFields.map((field) => (
            <FieldShape
              key={field.id}
              field={field}
              center={field.center}
              strokeColor={BRIGHT_NEUTRAL_COLOR}
              fillColor={field.color}
              zoom={zoom}
              selected={selectedArea?.kind == 'field' && selectedArea.fieldId == field.id}
              onClick={() => handleFieldClick?.(field.id)}
            />
          ))}
          {siteDetails.map((details) => {
            const coordinates = toJS(details.site.coordinates);
            const color =
              fieldDraft == undefined
                ? getMarkerColorForIrrigationStatus(getIrrigationStatus(details))
                : details.fieldId == undefined && isPointInPolygon(coordinates, fieldDraftPath)
                ? INTERACTION_COLOR
                : NEUTRAL_COLOR;
            return (
              <MouseEventFriendlyMarker
                key={details.site.id}
                coordinates={coordinates}
                fill={color}
                fillOpacity={fieldDraft == undefined ? 1.0 : 0.7}
                stroke={BRIGHT_NEUTRAL_COLOR}
                strokeWidth={1}
                label={
                  zoom >= 16 && (details.fieldId == undefined || zoom >= 18)
                    ? customerVisibleDeviceId(details.deviceIds)
                    : undefined
                }
                labelColor={color == INTERACTION_COLOR ? DARK_NEUTRAL_COLOR : BRIGHT_NEUTRAL_COLOR}
                highlighted={
                  (selectedArea?.kind == 'site' && details.site.id == selectedArea.siteId) ||
                  (selectedArea?.kind == 'field' && details.fieldId == selectedArea.fieldId)
                }
                handleClick={
                  fieldDraft == undefined
                    ? () => {
                        if (details.fieldId == undefined) {
                          handleSiteClick?.(details.site.id);
                        } else {
                          handleFieldClick?.(details.fieldId);
                        }
                      }
                    : undefined
                }
              />
            );
          })}
          <div style={{ position: 'absolute', right: 5, bottom: 5, color: 'white' }}>
            {showNdviLayer && (
              <IconButton
                className={classes.largeButton}
                color='inherit'
                aria-label=''
                onClick={() => {
                  toggleNdvi();
                }}
                edge='start'
                size='large'
              >
                {ndviShown ? (
                  <LocalFloristIcon className={classes.largeIcon} />
                ) : (
                  <LocalFloristTwoToneIcon className={classes.largeIcon} />
                )}
              </IconButton>
            )}
            {ndviShown && <NdviNavigation />}
          </div>
        </GoogleMap>
      </ErrorBoundary>
    );
  }
);

const IRRISAT_DATES_RESPONSE_DECODER = JsonDecoder.object(
  {
    items: JsonDecoder.array(
      JsonDecoder.object<NdviDate>({ date: JsonDecoder.string }, 'IrrisatDate'),
      'IrrisatDatesResponseItems'
    ),
  },
  'IrrisatDatesResponse'
);

async function fetchNdviDates(): Promise<readonly NdviDate[]> {
  const response = await fetch('https://irrisat-cloud.appspot.com/_ah/api/irrisat/v1/services/maps/dates');
  const rawData = await response.json();
  const data = await IRRISAT_DATES_RESPONSE_DECODER.decodeToPromise(rawData);
  return data.items;
}

const IRRISAT_LAYERS_RESPONSE_DECODER = JsonDecoder.object(
  {
    items: JsonDecoder.array(
      JsonDecoder.object({ mapid: JsonDecoder.string, name: JsonDecoder.string }, 'IrrisatLayer'),
      'IrrisatLayersResponseItems'
    ),
  },
  'IrrisatLayersResponse'
);

async function fetchNdviLayerId(date: string): Promise<string | undefined> {
  const layersUrl = `https://irrisat-cloud.appspot.com/_ah/api/irrisat/v1/services/maps/layers/${date}`;
  const response = await fetch(layersUrl);
  const rawData = await response.json();
  const data = await IRRISAT_LAYERS_RESPONSE_DECODER.decodeToPromise(rawData);
  const ndviLayer = data.items.find((layer) => layer.name === 'NDVI');
  return ndviLayer?.mapid;
}

function parseCoordinates(input: string): google.maps.LatLngLiteral | null {
  const parts = input.split(',').map((part) => parseFloat(part.trim()));
  if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
    const [lat, lng] = parts;
    if (isLatLngLiteral({ lat, lng })) {
      return { lat, lng };
    }
  }
  return null;
}

export default MarkersMap;
