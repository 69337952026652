'use client';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { AssistantStream } from 'openai/lib/AssistantStream';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/index.mjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BASE_URL } from '../../utils/consts';
import useAuthToken from '../../utils/useAuthToken';
import { Message, Messages, type MessageProps } from './Message';
import { useStreamHandlers } from './useStreamHandlers';

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column-reverse',
  height: '100%',
  width: '100%',
});

const InputForm = styled('form')({
  display: 'flex',
  width: '100%',
  padding: '10px',
  paddingBottom: '40px',
  order: 1,
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: '20px',
  },
});

const Input = styled('input')({
  flexGrow: 1,
  padding: '16px 24px',
  marginRight: '10px',
  borderRadius: '60px',
  border: '2px solid transparent',
  fontSize: '1em',
  backgroundColor: '#efefef',
  resize: 'none',
  '&:focus': {
    outline: 'none !important',
    borderColor: '#000',
    backgroundColor: 'white',
  },
  '@media (max-width: 600px)': {
    marginRight: 0,
  },
}).withComponent('textarea');

const Button = styled('button')({
  padding: '8px 24px',
  backgroundColor: '#000',
  color: 'white',
  border: 'none',
  fontSize: '1em',
  borderRadius: '60px',
  '&:disabled': {
    backgroundColor: 'lightgrey',
  },
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '6px 24px',
  },
});

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

type ChatProps = {
  functionCallHandler?: (toolCall: RequiredActionFunctionToolCall) => Promise<string>;
  initialMessage?: string;
};

const Chat = ({ functionCallHandler = () => Promise.resolve(''), initialMessage }: ChatProps) => {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [threadId, setThreadId] = useState('');
  const getIdToken = useAuthToken();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [initialMessageSent, setInitialMessageSent] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchWithAuth = useCallback(
    async (url: string, options: RequestInit) => {
      const token = await getIdToken();
      return fetch(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...options.headers,
        },
      });
    },
    [getIdToken]
  );

  const submitActionResult = useCallback(
    async (runId: string, toolCallOutputs: { output: string; tool_call_id: string }[]) => {
      const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${threadId}/actions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          runId: runId,
          toolCallOutputs: toolCallOutputs,
        }),
      });
      const stream = AssistantStream.fromReadableStream(response.body as ReadableStream<Uint8Array>);
      handleReadableStream(stream);
    },
    [threadId, fetchWithAuth] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { handleReadableStream, appendMessage } = useStreamHandlers(
    setMessages,
    setInputDisabled,
    submitActionResult,
    functionCallHandler,
    BASE_URL,
    setIsResponding
  );

  const sendMessage = useCallback(
    async (text: string, threadId: string) => {
      try {
        console.log(`Sending a new message to a thread: ${threadId}`);
        const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${threadId}/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content: text,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const stream = AssistantStream.fromReadableStream(response.body as ReadableStream<Uint8Array>);
        handleReadableStream(stream);
      } catch (error) {
        console.error('Error sending message:', error);
        appendMessage('assistant', '❌ Failed to send message. Please try again.');
        setInputDisabled(false);
        setIsResponding(false);
      }
    },
    [fetchWithAuth, handleReadableStream, appendMessage, setInputDisabled, setIsResponding]
  );

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const createThread = async () => {
      try {
        const res = await fetchWithAuth(`${BASE_URL}/llm/threads`, {
          method: 'POST',
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const data = (await res.json()) as { threadId: string };
        setThreadId(data.threadId);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setInputDisabled(false);
        setIsLoading(false);
      } catch (error) {
        console.error('Error creating thread:', error);
        appendMessage('assistant', '❌ Failed to start conversation. Please refresh the page.');
        setInputDisabled(true);
        setIsLoading(false);
      }
    };

    if (!threadId) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 10000);

      createThread();

      return () => clearTimeout(timeoutId);
    }

    if (threadId && initialMessage && !initialMessageSent) {
      setIsResponding(true);
      setIsLoading(true);
      sendMessage(`__initial_message__ ${initialMessage}`, threadId);
      appendMessage('assistant', 'Thinking...');
      setInitialMessageSent(true);
      // appendMessage('user', initialMessage);
      setUserInput('');
      setInputDisabled(true);
      scrollToBottom();
    }
  }, [fetchWithAuth, initialMessage, sendMessage, appendMessage, initialMessageSent, threadId]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userInput.trim() || isResponding) return;
    sendMessage(userInput, threadId);
    appendMessage('user', userInput);
    setUserInput('');
    setInputDisabled(true);
    setIsResponding(true);
    scrollToBottom();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!inputDisabled && userInput.trim()) {
        handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
      }
    }
  };

  return (
    <ChatContainer>
      {isLoading && messages.length === 0 ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <>
          <Messages>
            {messages.map((msg, index) => (
              <Message key={index} role={msg.role} text={msg.text} />
            ))}
            <div ref={messagesEndRef} />
          </Messages>
          <InputForm onSubmit={handleSubmit}>
            <Input
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder='Enter your question'
              rows={1}
              disabled={isResponding || isLoading}
            />
            <Button type='submit' disabled={inputDisabled || isResponding || isLoading}>
              Send
            </Button>
          </InputForm>
        </>
      )}
    </ChatContainer>
  );
};

export default Chat;
